<template>
<div class="top">
  <div class="pos" :class="{ menuShow: menuShow, menuClose: !menuShow }">
    <div class="menu">
      <ul>
        <li
          @click="
            menuIndex = 1;
            menuShow = !menuShow;
            player.play();
          "
          :class="{ active: menuIndex == 1 }"
        >
          {{currentInfo.is_try == 1 ? '音频内容' : '视频内容'}}
        </li>
        <li
          v-if="currentInfo.maps"
          @click="
            menuIndex = 2;
            menuShow = !menuShow;
          "
          :class="{ active: menuIndex == 2 }"
        >
        思维导图
        </li>
        <li
          v-if="examin"
          @click="
            menuIndex = 3;
            menuShow = !menuShow;
          "
          :class="{ active: menuIndex == 3 }"
        >
          随堂练习
        </li>
        <p></p>
      </ul>
      <p @click="menuShow = !menuShow">
        {{ menuShow ? "收起" : "展开" }}目录
        <i
          :class="{
            'el-icon-arrow-left': menuShow,
            'el-icon-arrow-right': !menuShow,
          }"
        ></i>
      </p>
    </div>
  </div>
  <div class="topMain">
    <div class="imgbox" v-show="menuIndex == 1">
      <div id="player" v-show="currentInfo.is_try==2"></div>
      <audio :style="{backgroundImage:currentInfo.cover ? 'url('+currentInfo.cover+')' : 'url('+$placeImgBg+')'}" controls ref="audio-player" v-show="currentInfo.is_try==1"></audio>
    </div>
    <div class="imgbox" v-show="menuIndex == 2">
      <img :src="currentInfo.maps"/>
    </div>
    <div class="imgbox examin" v-show="menuIndex == 3">
      <p>随堂练习</p>
      <div class="examinContent" v-if="examin">
        <img :src="isPass == 1 ? pass : noPass" v-if="isPass != 0" />
        <p>{{ examin.name }}</p>
        <div class="options">
          <p
            @click="examinSelect = item.id"
            :class="{ active: item.id == examinSelect }"
            v-for="item in examinOptions"
            :key="item.id"
          >
            <img :src="item.id == examinSelect ? radioAImg : radioImg" />{{
              item.name
            }}
          </p>
        </div>
        <button
          @click="examinSubmit"
          :class="{ gray: currentInfo.test_status == 1 }"
        >
          提交
        </button>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

export default {
  name: "detail",
  props:['currentInfo'],
  data() {
    return {
      menuIndex: 1,
      menuShow: false,
      examinOptions: [
        {
          id: 1,
          title: "选项一",
        },
      ],
      examin: null,
      examinSelect: 0,
      pass: "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinPass.png",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinNoPass.png",
      radioImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radio.jpg",
      radioAImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radioActive.jpg",
        player: "",

      examinList: [], //理论课程考试
      examinListCe:[],
      examinListShiCe:[],
      examinListShi: [],//实操课程考试
      isPass: 0,

      box: undefined,
      play: false, // 播放状态，true为正在播放
      duration: undefined, // 音乐总时长
      currentTime: undefined, //当前播放时长
    };
  },
  mounted() {
    
  },

  methods: {
    setAudio() {
      this.box = this.$refs["audio-player"];
      this.box.src = this.currentInfo.video_ids;
      console.log(this.box, "音频播放器DOM");
      const that = this;

      //  this.duration  =  this.formatTime(this.box.duration)
      // 绑定三个触发方法
     
   
      this.box.onplaying = function(){
        that.totalTime = parseInt(that.box.duration);
        that.timeStart = new Date();
      }
      this.box.onpause  = function(){
        that.totalTime = parseInt(that.box.duration);
        that.currentTime = parseInt(that.box.currentTime);
        that.addHistory();
        console.log('暂停')
      }
      // 当前数据可用是触发
      this.box.oncanplay = function () {
        console.log('1111111111')
        that.box.play()
      }
      // 音频播放完毕
      this.box.onended = function () {
        console.log("播放完毕，谢谢收听");
        that.totalTime = parseInt(that.box.duration);
        that.currentTime = parseInt(that.box.currentTime);
        that.addHistory();
      };
      // 音频播放完毕
      this.box.onerror = function () {
        console.log("加载出错！");
      };
    },
    async getVideo() {
     
      this.menuIndex = 1
      console.log(this.currentInfo)

      this.player = new Aliplayer(
          {
            id: "player",
            width: "100%",
            height: "450px",
            source: this.currentInfo.video_ids,
            cover: "",
            // isLive:true,
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "blabs",
                x: '50%',
                y: '50%',
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56,
              },
              {
                name: "thumbnail",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44,
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7,
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12,
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            // console.log("The player is created.");
          }
        );
      this.player.on("play", () => {
        this.videPlay()
      });
      this.player.on("pause", () => {
        this.videoPause();
      });
    },
   
    
    async getExamin() {
      this.examin = null;
      this.isPass = 0;
      if (this.type) return;
      let param = {
        //有chapter_id说明是节或讲的视频
        chapter_id: this.currentInfo.chapter_id ? "" : this.currentInfo.id,
        // 有chapter_id和lesson_id 说明是讲的视频 没有chapter_id说明是章的视频
        lesson_id: this.currentInfo.chapter_id && this.currentInfo.lesson_id  || !this.currentInfo.chapter_id ? "": this.currentInfo.id,
        // 有lesson_id说明是讲的视频
        speak_id: this.currentInfo.lesson_id ? this.currentInfo.id : '',
      };
      let myParam = {};
      for (let [k, v] of Object.entries(param)) {
        if (v) {
          myParam[k] = v;
        }
      }
      let res = await this.$request.tuoyuExamin(myParam);
      if (res.code == 0) {
        if (!res.data || !res.data.id) {
          return;
        }
        this.examin = res.data;

        this.examinOptions = [];
        this.examinSelect = -1;

        if (this.examin.type == 1) {
          //选择题
          if (this.examin.item_one) {
            this.examinOptions.push({
              name: this.examin.item_one,
              id: 1,
            });
          }
          if (this.examin.item_two) {
            this.examinOptions.push({
              name: this.examin.item_two,
              id: 2,
            });
          }
          if (this.examin.item_three) {
            this.examinOptions.push({
              name: this.examin.item_three,
              id: 3,
            });
          }
          if (this.examin.item_four) {
            this.examinOptions.push({
              name: this.examin.item_four,
              id: 4,
            });
          }
          if (this.currentInfo.test_status == 1) {
            //已通过测试，直接显示
            this.isPass = 1;
            this.examinSelect = this.examin.answer;
          }
        } else {
          this.examinOptions = [
            {
              name: "正确",
              id: 1,
            },
            {
              name: "错误",
              id: 2,
            },
          ];
          if (this.currentInfo.test_status == 1) {
            //已通过测试，直接显示
            this.isPass = 1;
            this.examinSelect = this.examin.judge;
          }
        }
      } else {
        this.$toast(res.msg);
      }
    },
    async examinSubmit() {
      if (this.currentInfo.test_status == 1) {
        return;
      }
      if (this.examinSelect == -1) {
        this.$toast("请选择答案");
        return;
      }
      let param = {
        test_id: this.examin.id,
        type: this.examin.type,
      };
      if (this.examin.type == 1) {
        //选择题
        param.item = this.examinSelect;
      } else {
        param.judge = this.examinSelect;
      }
      this.pageLoading = true;
      let res = await this.$request.tuoyuExaminSubmit(param);
      this.pageLoading = false;
      if (res.code == 0) {
        console.log(res);
        if (this.examin.type == 1) {
          this.isPass = res.data.choose_answer == this.examinSelect ? 1 : 2;
        } else {
          this.isPass = res.data.judge_answer ? 1 : 2;
        }
        this.currentInfo.test_status = this.isPass;
      } else {
        this.$toast(res.msg);
      }
    },
    videPlay() {
      console.log("播放");
      // console.log(this.player.getDuration())
      this.totalTime = parseInt(this.player.getDuration());
      this.timeStart = new Date();
    },
    videoPause() {
      console.log("暂停11");
      this.totalTime = parseInt(this.player.getDuration());
      this.currentTime =  parseInt(this.player.getCurrentTime());

      this.addHistory();
    },
    async addHistory() {
      if (this.type) return;
      console.log("history");
      let data = this.currentInfo;
      this.duration = this.timeStart
        ? parseInt((new Date().getTime() - this.timeStart.getTime()) / 1000)
        : 0;
      console.log(this.currentInfo);
      console.log(this.currentInfo.lesson_id)
      console.log(this.duration);
      // if(!data || this.duration<5) return
      this.timeStart = 0;
      let res = await this.$request.tuoyuRecord({
        //有chapter_id说明是节或讲的视频
        chapter_id: this.currentInfo.chapter_id ? 0 : this.currentInfo.id, 
        // 有chapter_id和lesson_id 说明是讲的视频 没有chapter_id说明是章的视频
        lesson_id: this.currentInfo.chapter_id && this.currentInfo.lesson_id  || !this.currentInfo.chapter_id? 0: this.currentInfo.id,
        // 有lesson_id说明是讲的视频
        speak_id: this.currentInfo.lesson_id ? this.currentInfo.id : 0,
        discipline_id: this.$route.query.id,
        status: this.currentTime && this.currentTime === this.totalTime ? 1 : 0,
        duration: this.duration,
        playmode_id: this.currentInfo.is_try,
      });
      // if (res.code == 0) {
      //   console.log(res.data);
      // }
    },
  },
  watch: {
    currentInfo() {
      console.log('watch',this.currentInfo.is_try)
      console.log(this.currentInfo)
      if (this.player) {
        this.videoPause()
        this.player.dispose()
        this.player = null
      }
      if(this.box){
        this.box.pause()
      }
       if(this.currentInfo && this.currentInfo.id){
        if(this.currentInfo.is_try == 2){
          this.getVideo()
        }else{
          this.setAudio()
        }
          this.getExamin()
       }
    },
    menuIndex(e) {
      if (e != 1 && this.player) {
        this.player.pause();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import url("https://service.wormhoo.com/libs/js/vant2/index.css");

.top{
  position: relative;
  height: 450px;
  .pos {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 180px;
    z-index: 9999;
    // overflow: hidden;
    .menu {
      z-index: 9999;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      position: relative;
      width: 180px;
      transition: all 0.3s;
      ul {
        height: 450px;
        width: 180px;
        overflow: hidden;
        text-align: center;
        display: flex;
        flex-direction: column;
        li {
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          background: rgba($color: #000000, $alpha: 0.6);
          border-bottom: 1px solid #fff;
        }
        p {
          flex: 1;
          background: rgba($color: #000000, $alpha: 0.6);
        }
        li:hover,
        .active {
          background: rgba($color: #2e66d0, $alpha: 0.8);
          color: #fff;
        }
      }

      > p {
        position: absolute;
        right: -38px;
        top: 50%;
        transform: translateY(-50%);
        background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/menu.png");
        width: 38px;
        height: 145px;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
      }
    }
    
  }
  .menuShow {
    left: 0;
  }
  .menuClose {
    left: -180px;
  }
  .topMain{
    height: 100%;
    width: 100%;
    .imgbox {
      height: 100%;
      width: 100%;
    }
  }
}
.imgbox{
  background: #f9f9f9;
  audio{
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.examin{
  padding: 0 30px;
  margin-bottom: 20px;
  > p {
    text-align: center;
    border-bottom: 1px dashed #d2d2d2;
    font-size: 24px;
    padding-top: 34px;
    padding-bottom: 24px;
  }
  .examinContent {
    padding: 20px 0;
    position: relative;
    > img {
      position: absolute;
      top: 31px;
      right: 31px;
    }
    > p {
      font-size: 20px;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .options {
      padding-left: 14px;
      p {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 10px 0;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
      p.active {
        background: none;
        color: #0080d6;
      }
    }
    > button {
      width: 220px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      background: #0080d6;
      color: #fff;
      border: 0;
      border-radius: 5px;
      margin: 30px auto;
      display: block;
      cursor: pointer;
    }
  }
  .choose {
    display: flex;
    justify-content: flex-end;
    p {
      cursor: pointer;
      width: 122px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      border-radius: 3px;
      border: 0;
      &:first-of-type {
        background: #0080d6;
        color: #fff;
      }
      &:nth-of-type(2) {
        margin-left: 20px;
        background: #f9f9f9;
        color: #0080d6;
      }
    }
    .active {
      color: #0080d6;
    }
    .gray {
      background: #e4e4e4;
      border: 1px solid #e4e4e4;
      color: #666;
    }
  }
}
#player {
    width: 100%;
    height: 100%;
    background: #000;
  }
</style>

<style lang="scss">
  video{
    height: 100%;
  }
</style>
