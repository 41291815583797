var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"pos",class:{ menuShow: _vm.menuShow, menuClose: !_vm.menuShow }},[_c('div',{staticClass:"menu"},[_c('ul',[_c('li',{class:{ active: _vm.menuIndex == 1 },on:{"click":function($event){_vm.menuIndex = 1;
            _vm.menuShow = !_vm.menuShow;
            _vm.player.play();}}},[_vm._v(" "+_vm._s(_vm.currentInfo.is_try == 1 ? '音频内容' : '视频内容')+" ")]),(_vm.currentInfo.maps)?_c('li',{class:{ active: _vm.menuIndex == 2 },on:{"click":function($event){_vm.menuIndex = 2;
            _vm.menuShow = !_vm.menuShow;}}},[_vm._v(" 思维导图 ")]):_vm._e(),(_vm.examin)?_c('li',{class:{ active: _vm.menuIndex == 3 },on:{"click":function($event){_vm.menuIndex = 3;
            _vm.menuShow = !_vm.menuShow;}}},[_vm._v(" 随堂练习 ")]):_vm._e(),_c('p')]),_c('p',{on:{"click":function($event){_vm.menuShow = !_vm.menuShow}}},[_vm._v(" "+_vm._s(_vm.menuShow ? "收起" : "展开")+"目录 "),_c('i',{class:{
            'el-icon-arrow-left': _vm.menuShow,
            'el-icon-arrow-right': !_vm.menuShow,
          }})])])]),_c('div',{staticClass:"topMain"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuIndex == 1),expression:"menuIndex == 1"}],staticClass:"imgbox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentInfo.is_try==2),expression:"currentInfo.is_try==2"}],attrs:{"id":"player"}}),_c('audio',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentInfo.is_try==1),expression:"currentInfo.is_try==1"}],ref:"audio-player",style:({backgroundImage:_vm.currentInfo.cover ? 'url('+_vm.currentInfo.cover+')' : 'url('+_vm.$placeImgBg+')'}),attrs:{"controls":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuIndex == 2),expression:"menuIndex == 2"}],staticClass:"imgbox"},[_c('img',{attrs:{"src":_vm.currentInfo.maps}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuIndex == 3),expression:"menuIndex == 3"}],staticClass:"imgbox examin"},[_c('p',[_vm._v("随堂练习")]),(_vm.examin)?_c('div',{staticClass:"examinContent"},[(_vm.isPass != 0)?_c('img',{attrs:{"src":_vm.isPass == 1 ? _vm.pass : _vm.noPass}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.examin.name))]),_c('div',{staticClass:"options"},_vm._l((_vm.examinOptions),function(item){return _c('p',{key:item.id,class:{ active: item.id == _vm.examinSelect },on:{"click":function($event){_vm.examinSelect = item.id}}},[_c('img',{attrs:{"src":item.id == _vm.examinSelect ? _vm.radioAImg : _vm.radioImg}}),_vm._v(_vm._s(item.name)+" ")])}),0),_c('button',{class:{ gray: _vm.currentInfo.test_status == 1 },on:{"click":_vm.examinSubmit}},[_vm._v(" 提交 ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }